// @flow

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { withPrefix } from 'gatsby'
import * as React from 'react'

import { colors, fonts, media } from '@toggl/style'

import * as Badge from '../badge'
import { IconFacebook } from '../icons/IconFacebook'
import { IconGitHub } from '../icons/IconGitHub'
import { IconInstagram } from '../icons/IconInstagram'
import { IconLinkedIn } from '../icons/IconLinkedIn'
import { IconTwitter } from '../icons/IconTwitter'
import { IconYouTube } from '../icons/IconYouTube'
import * as LogoSVG from '../logos'
import { heading5, paragraph4 } from '../text'

import togglTrackIcon from './assets/icon-track.svg'
import togglLogoCompany from './assets/logo-togglcompany.svg'
type Props = {
  children?: React.Node,
  openPositions?: number,
  Link: any,
  product?: 'track' | 'plan' | 'hire' | 'work',
}

export function Wrapper({ children, Link, openPositions, product }: Props) {
  const hasChildren = !!children
  const trackFooter = product === 'track'

  return (
    <Root hasChildren={hasChildren}>
      {trackFooter && (
        <TrackFooter>
          <SizeWrapper>
            <TrackOnly>
              <TrackIcon>
                <img src={togglTrackIcon} alt='Icon of the Track company' />
              </TrackIcon>
              <Lists>{children}</Lists>
            </TrackOnly>
          </SizeWrapper>
        </TrackFooter>
      )}

      <GlobalFooter>
        <SizeWrapper>
          <GlobalLinkContainer>
            <GlobalLogo>
              <img src={togglLogoCompany} alt='Logo of the Toggl company' />
            </GlobalLogo>
            <Separator />
            <GlobalLinks>
              <li>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://toggl.com/blog/'
                >
                  Blog
                </a>
              </li>
              <li>
                <Link to='/about/'>About us</Link>
              </li>
              <li>
                <Link to='/jobs/'>
                  Working at Toggl
                  {openPositions && <Badge.Small>{openPositions}</Badge.Small>}
                </Link>
              </li>
            </GlobalLinks>
          </GlobalLinkContainer>

          <OtherTools trackFooter={product == 'track'}>
            <p>
              {trackFooter
                ? 'Discover our other tools:'
                : ' Discover our tools:'}
            </p>
            {!trackFooter && (
              <ToolBox href={withPrefix('/')} data-product='track'>
                <LogoSVG.Track />
                <span>Effortless time tracking</span>
              </ToolBox>
            )}
            <ToolBox href={withPrefix('/plan/')} data-product='plan'>
              <LogoSVG.Plan />
              <span>Beautifully simple team planning</span>
            </ToolBox>
            <ToolBox href={withPrefix('/hire/')} data-product='hire'>
              <LogoSVG.Hire />
              <span>Easy full-cycle hiring</span>
            </ToolBox>
            <ToolBox href={withPrefix('/work/')} data-product='work'>
              <LogoSVG.Work />
              <span>Efficient expense management</span>
            </ToolBox>
          </OtherTools>
          <Social>
            <SocialLinkWrapper>
              <a
                href='https://www.linkedin.com/company/toggl/'
                rel='noreferrer'
                aria-label="Visit Toggl's LinkedIn profile"
                target='_blank'
              >
                <IconLinkedIn />
              </a>
              <a
                href='https://www.instagram.com/toggltrack'
                target='_blank'
                rel='noreferrer'
                aria-label="Visit Toggl's Instagram profile"
              >
                <IconInstagram />
              </a>
              <a
                href='https://x.com/toggl'
                target='_blank'
                rel='noreferrer'
                aria-label="Visit Toggl's X (Twitter) profile"
              >
                <IconTwitter />
              </a>
              <a
                href='https://www.youtube.com/toggl'
                target='_blank'
                rel='noreferrer'
                aria-label="Visit Toggl's YouTube profile"
              >
                <IconYouTube />
              </a>
              <a
                href='https://www.facebook.com/Toggl/'
                target='_blank'
                rel='noreferrer'
                aria-label="Visit Toggl's Facebook profile"
              >
                <IconFacebook />
              </a>
              <a
                href='https://github.com/toggl'
                target='_blank'
                rel='noreferrer'
                aria-label="Visit Toggl's GitHub profile"
              >
                <IconGitHub />
              </a>
            </SocialLinkWrapper>
          </Social>
          <LegalBit>
            <GlobalLinks>
              <Link to='/legal/'>Legal Center</Link>
              <a href='https://status.toggl.com/'>Status</a>
            </GlobalLinks>

            <span>&#169; {new Date().getFullYear()} Toggl</span>
          </LegalBit>
        </SizeWrapper>
      </GlobalFooter>
    </Root>
  )
}

export function Minimized({ Link }: Props) {
  return (
    <MiniFooter>
      <LegalBit>
        <GlobalLinks>
          <Link to='/legal/'>Legal Center</Link>
        </GlobalLinks>

        <span>&#169; {new Date().getFullYear()} Toggl</span>
      </LegalBit>
    </MiniFooter>
  )
}

const MiniFooter: React.ComponentType<any> = styled.footer`
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  padding: 35px 30px 10px;
  a:hover {
    color: ${colors.yellow100};
  }
  ul {
    margin: 0;
    padding: 0;
  }
`

export function List({
  title,
  children,
}: {
  title: string,
  children: React.Node,
}) {
  return (
    <RootList>
      <Title>{title}</Title>
      <ul>{children}</ul>
    </RootList>
  )
}

const Root: React.ComponentType<any> = styled.footer`
  position: relative;
  z-index: 100;
  a {
    color: ${colors.darkPurple20} !important;
    display: inline-block;
    &:hover {
      color: ${colors.peach10} !important;
    }
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    ${paragraph4};
  }
`

const RootList: React.ComponentType<any> = styled.div`
  padding-right: 30px;
  min-width: 12rem;
  flex: 1;
  margin-bottom: 30px;
  color: ${colors.darkPurple20};
  ${media.mq[2]} {
    flex: 1;
  }
`

const Title = styled.h5`
  ${heading5};
  font-size: 1.1rem;
  color: ${colors.peach10};
  margin: 10px 0;
  ${media.mq[1]} {
    margin-bottom: 15px;
  }
`

const Lists: React.ComponentType<any> = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  ${media.mq[2]} {
    flex-wrap: no-wrap;
    min-width: 14rem;
  }
  > * {
    flex: 1;

    ${media.mq[1]} {
      &:first-of-type {
        flex: 0.8;
      }
    }
  }
  li > a {
    padding: 10px 0;
  }
  a {
    text-decoration: none;
    cursor: pointer;
    &:hover {
      color: ${colors.pink100} !important;
      ${Badge.Small} {
        background-color: ${({ theme }) => theme.primary};
        color: ${colors.darkPurple80};
      }
    }
  }
`

const TrackFooter = styled.section`
  background: ${colors.darkPurple90};
  ${media.mq[2]} {
    padding: 15px 0 0;
  }
`

const TrackOnly = styled.div`
  ${media.mq[2]} {
    gap: 80px;
    display: flex;
  }
`

const TrackIcon = styled.div`
  margin-bottom: 30px;
  img {
    height: 50px;
  }
`

const Social = styled.div`
  position: relative;
  margin: 50px 0 5px;
  ${media.mq[1]} {
    display: flex;
    justify-content: center;
    &:before {
      content: '';
      position: absolute;
      height: 2px;
      background: ${colors.darkPurple40};
      width: 100%;
      left: 0;
      top: 50%;
    }
  }
  a {
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  svg {
    height: 22px;
    width: 22px;
  }
`

const SocialLinkWrapper = styled.div`
  background: ${colors.darkPurple100};
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  ${media.mq[1]} {
    padding: 10px 30px;
  }
`

const GlobalFooter = styled.section`
  background: ${colors.darkPurple100};
`

const SizeWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 55px 30px 30px;
`

const GlobalLinkContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`

const Separator = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  height: 2px;
  background: ${colors.darkPurple40};
  margin-right: 15px;
`

const GlobalLogo = styled.div`
  width: 50%;
  max-width: 440px;
  img {
    width: 100%;
  }
`

const GlobalLinks = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 30px;
  a {
    padding: 10px 0;
  }
`

const ToolBox = styled.a`
  font-family: ${fonts.gtHaptikMedium};
  display: block;
  border-radius: 8px;
  position: relative;
  padding: 20px 25px;
  background: ${colors.darkPurple90};
  overflow: clip;
  flex: 1 1 100%;
  span {
    display: block;
    margin-top: 5px;
  }
  svg {
    height: 25px;
    width: auto;
  }
  &:hover {
    font-family: ${fonts.gtHaptikMediumRotalic};
    svg {
      fill: ${colors.peach10};
    }
  }
  &[data-product='track'] {
    &:before {
      background-image: url(${require('./assets/illo-track.svg').default});
    }
    &:hover {
      background: ${colors.pink100};
    }
  }
  &[data-product='plan'] {
    &:before {
      background-image: url(${require('./assets/illo-plan.svg').default});
    }
    &:hover {
      background: ${colors.violet};
    }
  }
  &[data-product='hire'] {
    &:before {
      background-image: url(${require('./assets/illo-hire.svg').default});
    }
    &:hover {
      background: ${colors.orange};
    }
  }
  &[data-product='work'] {
    &:before {
      background-image: url(${require('./assets/illo-work.svg').default});
    }
    &:hover {
      background: ${colors.teal100};
    }
  }
  @media (min-width: 480px) {
    min-width: 370px;
    flex: 1;
    &:before {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-size: auto 101%;
      background-repeat: no-repeat;
      background-position: right;
    }
  }
`

const OtherTools = styled.div`
  margin: 35px auto;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  p {
    color: ${colors.darkPurple30};
    font-family: ${fonts.gtHaptikMedium};
    padding-right: 20px;
    line-height: 1.2;
    width: 100%;
  }
  ${({ trackFooter }: { trackFooter?: boolean }) =>
    trackFooter
      ? css`
      ${ToolBox} {
        ${media.mq[2]} {
          min-width: auto;
        }
      }
  }
  `
      : ''}
`

const LegalBit = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
  align-items: center;
  justify-content: space-between;
  ${paragraph4};
  color: ${colors.darkPurple30};
  ${media.mq[1]} {
    margin-top: -15px;
    margin-bottom: 10px;
  }
`
